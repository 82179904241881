<template>
  <v-container fluid>
    <v-card class="container pa-5 mt-3">
      <v-data-table
        :headers="headers"
        :items="countries"
        :search="search"
        :items-per-page="5"
        class=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              :label="$t('search')"
              append-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isInRole('11')"
              color="primary"
              dark
              class="mb-2"
              to="/countryCities"
            >
              {{ $t("add") }}

              <v-icon> mdi-plus </v-icon>
            </v-btn>

            <confirm-dialog
              :openDialog="dialogDelete"
              :onClicked="deleteItemConfirm"
              :onClose="closeDelete"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top v-if="isInRole('12')">
              <template v-slot:activator="{ on, attrs }">
                <router-link :to="'/countryCities/' + item.guid">
                  <v-icon class="ma-2" v-bind="attrs" v-on="on">
                    mdi-pencil
                  </v-icon>
                </router-link>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>

            <v-tooltip top v-if="isInRole('13')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red darken-2"
                  icon
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editingItem: null,
      editingItemIndex: -1,
      search: "",
      headers: [
        {
          text: this.$t("country.countryName"),
          value: "countryName",
        },
        // { text: this.$t("prescriptions.items"), value: "items" },
        // { text: this.$t("notes"), value: "note" },
        { text: "", value: "actions" },
      ],
      countries: [],
      cities: [],

      editedItem: {
        groupName: "",
        items: [],
        note: "",
      },
      defaultItem: {
        groupName: "",
        items: [],
        note: "",
      },
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    this.refreshTable();

    axios.get("PrescriptionItems").then((response) => {
      this.items = response.data.data;
    });
  },
  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editingItem = item;
        this.editingItemIndex = index;
      } else {
        this.editingItem = null;
        this.editingItemIndex = -1;
      }
    },

    editItem(item) {
      this.editedIndex = this.countries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.countries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.countries[this.editedIndex];

      axios
        .delete("Country/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      axios.get("Country").then((response) => {
        this.countries = response.data.data;
      });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.groups[this.editedIndex];

          updatedItem.itemName = this.editedItem.groupName;
          updatedItem.note = this.editedItem.note;

          axios
            .post("PrescriptionGroup/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.groups[this.editedIndex], this.editedItem);
        } else {
          axios
            .post("PrescriptionGroup/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
